/** @jsx jsx */
import { jsx, Flex, Styled } from "theme-ui"
import { Link } from "gatsby"
import useHubData from "../static_queries/useHubData"
import Img from "gatsby-image"

const transformIn = {
  transform: "translateX(0px)",
  transition: "transform 0.5s ease-out",
}
const transformOut = {
  transform: ["none", "translateX(10px)"],
  transition: ["none", "transform 0.5s ease-out"],
}
const hoverStyles = {
  "a:hover": {
    opacity: 1,
    li: {
      picture: { opacity: 0.8, transition: "opacity 0.3s ease" },
      h2: { ...transformOut },
      p: { ...transformOut },
    },
  },
}

export default function HubList() {
  const blogData = useHubData()
  function renderBlogData() {
    return (
      <div sx={hoverStyles}>
        {blogData
          .filter((blog) => blog.node.frontmatter.title !== "")
          .map((blog) => {
            return (
              <Link to={`/${blog.node.fields.slug}`} key={blog.node.id}>
                <li
                  sx={{
                    opacity: "inherit",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: ["column", "row"],
                    minHeight: ["38vh", "250px"],
                    height: ["auto", "30vh"],
                    marginBottom: 0,
                  }}
                  key={blog.node.fields.slug}
                >
                  <div
                    sx={{
                      width: "100%",
                      height: ["33vh", "100%"],
                      overflow: "hidden",
                      backgroundColor: "text",
                    }}
                  >
                    <Img
                      sx={{
                        img: {
                          objectFit: "cover",
                          objectPosition: "50% 50%",
                          minHeight: ["100%", 0],
                          minWidth: ["auto", "100%"],
                          height: ["auto", "100%"],
                        },
                        picture: {
                          opacity: 1,
                          transition: "opacity 0.3s ease",
                        },
                      }}
                      fluid={
                        blog.node.frontmatter.hero_image.childImageSharp.fluid
                      }
                      alt={blog.node.frontmatter.title}
                    />
                  </div>
                  <Flex
                    sx={{
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: ["1.5rem 1.25rem", "1.5rem 1.25rem", "3rem"],
                      borderBottom: "1px solid #ebebeb",
                      minWidth: ["auto", "70%"],
                    }}
                  >
                    <Styled.h2 sx={{ ...transformIn, marginBottom: "0.5rem" }}>
                      {blog.node.frontmatter.title}
                    </Styled.h2>
                    <Styled.p sx={{ ...transformIn, maxWidth: "900px" }}>
                      {blog.node.frontmatter.description}
                    </Styled.p>
                  </Flex>
                </li>
              </Link>
            )
          })}
      </div>
    )
  }
  return (
    <section>
      <ul sx={{ listStyle: "none", margin: 0 }}>{renderBlogData()}</ul>
    </section>
  )
}
