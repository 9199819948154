/** @jsx jsx */
import { jsx } from "theme-ui"

import Layout from "../components/Layout"
import HubList from "../components/HubList"

export default function IndexPage() {
  return (
    <Layout page="home" bgColor="inherit">
      <section>
        <HubList />
      </section>
    </Layout>
  )
}
