import { graphql, useStaticQuery } from "gatsby"

export default function useHubData() {
  const data = useStaticQuery(graphql`
    query getHubData {
      allMdx(
        sort: { order: ASC, fields: fields___priority }
        filter: { fields: { levels: { L1: { eq: null } } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              hero_image {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              description
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return data.allMdx.edges
}
